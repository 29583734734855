define("js2-admin/models/quick-filter", ["exports", "js-common/models/quick-filter"], function (_exports, _quickFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _quickFilter.default.extend({});

  _exports.default = _default;
});