define("js2-admin/pods/components/search-db/search-results/component", ["exports", "ember-lifeline/mixins/run"], function (_exports, _run) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const {alias} = computed;
  var _default = Ember.Component.extend(_run.default, {
    classNames: ['box', 'box-solid', 'memo-panel'],
    //, '.loader-overlay-container'
    tagName: '',
    // defaults for fed in properties:
    filteredTags: [],
    dbSelection: [],
    canAdd: false,
    canEdit: false,
    canCreateMemo: false,
    tagSelection: [],
    isCountySelected: false,
    ungroupedDbs: null,
    ungroupedDbsSorting: ['fullName'],
    showDetails: false,

    /* list of databases selected for create memos */
    selectedDatabases: [],
    currentMatter: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(function () {
        // clear the selection on every visit
        _this.set('selectedDatabases', []);
      });
    },
    dbsByTag: null,
    dbsByTagLength: 0,
    noTagDbs: null,
    dbsByTagObserver: Ember.on('init', Ember.observer('dbSelection', 'isCountySelected', function () {
      var dbs = this.get('dbSelection');

      if (!dbs) {
        return;
      }

      var dbsByTag = {};
      var groupedTags = [];
      dbs.forEach(function (db) {
        db.get('dbHasTags').filterBy('tagCategory', 'primary').mapBy('dbTag').filter(function (tag) {
          // keep only general tags (i.e. no parent id)
          return !tag.get('parent.id');
        }).forEach(function (tag) {
          var tagId = tag.get('id');

          if (!dbsByTag[tagId]) {
            dbsByTag[tagId] = [];
          }

          dbsByTag[tagId].push(db);
          groupedTags.push(db);
        });
      });
      var counter = 0;

      for (var tagId in dbsByTag) {
        dbsByTag[tagId] = dbsByTag[tagId].uniq();
        counter++;
      }

      var noTagDbs = dbs.filter(function (db) {
        return !groupedTags.includes(db);
      });

      if (this.get('isCountySelected')) {
        this.set('ungroupedDbs', dbs);
      }

      this.set('dbsByTag', dbsByTag);
      this.set('dbsByTagLength', counter);
      this.set('noTagDbs', noTagDbs);
    })),
    q: null,
    queryChangeObserver: Ember.observer('q', function () {
      this.debounceTask('updateQ', 600);
    }),
    updateQ: function updateQ() {
      this.attrs.queryChange(this.get('q'));
    },
    showHidden: false,
    showTagsColumn: Ember.computed('currentTab', function () {
      var currentTab = this.get('currentTab');
      return currentTab === 'general';
    }),
    sortedUngroupedDbs: Ember.computed.sort('ungroupedDbs', 'ungroupedDbsSorting'),
    actions: {
      removeTagFromFilter: function removeTagFromFilter(tag) {
        this.get('tagSelection').removeObject(tag);
        this.attrs.tagSelectionChange(this.get('tagSelection'));
      },
      showPassword: function showPassword(dbCredential) {
        dbCredential.getPlainPassword().then(function (response) {
          dbCredential.set('plainPassword', response.plain_password);
        });
      },
      changeHidden: function changeHidden() {
        this.toggleProperty('showHidden');
        this.attrs.hiddenChange(this.get('showHidden'));
      },
      toggleDatabaseToSelection: function toggleDatabaseToSelection(db) {
        var selected = this.get('selectedDatabases');

        if (selected.includes(db)) {
          selected.removeObject(db);
        } else {
          selected.pushObject(db);
        }
      },
      toggleAllDatabaseInTagToSelection: function toggleAllDatabaseInTagToSelection(tag) {
        var _this2 = this;

        this.get('dbsByTag')[tag.get('id')].forEach(function (db) {
          _this2.send('toggleDatabaseToSelection', db);
        });
      },
      toggleAllDatabases: function toggleAllDatabases() {
        var _this3 = this;

        this.get('dbSelection').forEach(function (db) {
          _this3.send('toggleDatabaseToSelection', db);
        });
      },
      clearDatabaseSelection: function clearDatabaseSelection() {
        this.set('selectedDatabases', []);
      },
      createMemosForSelectedDatabases: function createMemosForSelectedDatabases() {
        var dbs = this.get('selectedDatabases');
        this.send('clearDatabaseSelection');
        this.attrs.createDbMemo(dbs, false);
      }
    }
  });

  _exports.default = _default;
});